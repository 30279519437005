<template>
    <div class="account-tabs">
        <div ref="content" class="account-tabs__content">
            <template v-for="(item, i) in items" :key="i">
                <div
                    v-if="item.icon !== ''"
                    :class="{
                        'account-tabs__item': true,
                        active: activeIndex === i,
                        line: true
                    }"
                    @click="clicked($event, i)"
                >
                    <div class="text">{{ $t(`General.${item.link?.replaceAll('-', '_')}`) }}</div>
                    <AtomIcon v-if="item.icon" :name="item.icon" />
                </div>
            </template>
            <div class="account-tabs__space" />
        </div>
    </div>
</template>

<script lang="ts" setup>
type Items = {
    link?: string;
    icon?: string;
    activeIndex?: number;
};

interface Props {
    items: Items[];
    activeIndex?: number;
}

const props = withDefaults(defineProps<Props>(), {
    activeIndex: 0
});

const emit = defineEmits<{
    (e: 'update:activeIndex' | 'tabChanged', index: number): void;
    (e: 'click', event: MouseEvent, index: number): void;
}>();

const content = ref();
const leftVisible = ref(false);
const rightVisible = ref(false);
let resizeTimeout: NodeJS.Timeout;
let resizeObserver: ResizeObserver | null = null;

const clicked = (event: MouseEvent, index: number) => {
    emit('click', event, index);
    if (event.defaultPrevented) {
        return;
    }
    if (index !== props.activeIndex) {
        emit('update:activeIndex', index);
        emit('tabChanged', index);
    }
};

const checkOverflow = () => {
    if (content?.value?.scrollWidth > content?.value?.offsetWidth) {
        leftVisible.value = content.value.scrollLeft > 0;
        rightVisible.value =
            content.value.scrollLeft + content.value.offsetWidth < content.value.scrollWidth;
    } else {
        leftVisible.value = false;
        rightVisible.value = false;
    }
};

watch(
    () => props.items,
    async () => {
        await nextTick();
        checkOverflow();
    }
);

onMounted(() => {
    content.value.addEventListener('scroll', checkOverflow);
    checkOverflow();

    if (ResizeObserver) {
        resizeObserver = new ResizeObserver(() => {
            clearTimeout(resizeTimeout);

            resizeTimeout = setTimeout(() => {
                checkOverflow();
            }, 100);
        });

        resizeObserver.observe(content.value);
    }
});

onBeforeUnmount(() => {
    if (resizeObserver) {
        resizeObserver.disconnect();
    }
    if (content.value) {
        content.value.removeEventListener('scroll', checkOverflow);
    }
});
</script>

<style lang="scss" scoped>
.account-tabs {
    user-select: none;
    overflow: hidden;
    position: relative;

    @include helper-font-size(default);
    @include helper-color(text-secondary);
    @include helper-color-bg(white);
    @include helper-font-weight(medium);

    &__content {
        height: rem(48);
        overflow: auto;
        display: flex;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        min-width: rem(164);
        transition: border-color 0.3s, color 0.3s;

        @include helper-svg-size(16);

        .text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: rem(116);
            margin-left: rem(11);
        }

        svg {
            margin-left: sp(xs);
        }

        &:not(.active, .disabled):hover {
            @include helper-color(state-hover);
        }

        &.line {
            flex-direction: row-reverse;

            @include helper-border-b();

            svg {
                margin-right: sp(xs);
                margin-left: 0;
            }

            &.active {
                @include helper-color(state-default);
                @include helper-color-bg(light-gray);
                @include helper-border-base($setting-color-state-default, solid);
                @include helper-border-radius(rem(5) rem(5) 0 0);
            }
        }
    }

    &__space {
        flex-basis: 100%;

        @include helper-border-b();
    }
}
</style>
